import * as ID from '@/constants/identifier';
import { useGuest } from '@/context/guest';
import useLang from '@/hooks/useLang';

import {
  ENABLE_QR_INVITATION,
  ENABLE_RSVP,
  ENABLE_GIFT_CARD,
  ENABLE_LIVE_STREAMING,
} from '@/constants/feature-flags';

import txt from './locales';
import { IS_BOY_FIRST } from '@/constants';

/**
 * Documentation
 * name -> will be show as menu title
 * value -> identifier for unique section. Will scroll to value element
 * invitationOnly -> only show menu if user in invitation mode
 * link -> will direct to new page if link available
 * offset -> used for add extra space when scroll
 */
export default function useMenuData() {
  const lang = useLang();
  const { guest } = useGuest();
  const { code } = guest;

  const menuData = [
    {
      name: txt.accessCard[lang],
      value: ID.ID_QRCODE,
      invitationOnly: true,
      link: '',
      offset: -16,
      show: ENABLE_QR_INVITATION && code,
    },
    {
      name: IS_BOY_FIRST ? txt.groom[lang] : txt.bride[lang],
      value: ID.ID_COUPLE_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: true,
    },
    {
      name: txt.details[lang],
      value: ID.ID_DETAIL_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: true,
    },
    {
      name: txt.rsvp[lang],
      value: ID.ID_RSVP_SECTION,
      invitationOnly: true,
      link: '',
      offset: 0,
      show: ENABLE_RSVP,
    },
    {
      name: txt.live[lang],
      value: ID.ID_YOUTUBE_LIVE,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: ENABLE_LIVE_STREAMING,
    },
    {
      name: txt.gift[lang],
      value: ID.ID_GIFT_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: ENABLE_GIFT_CARD,
    },
  ];

  return menuData.filter((i) => i.show);
}
