import React, { useEffect } from 'react';

import { string } from 'prop-types';
import { Box } from '@chakra-ui/react';

import {
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';

import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import { ENABLE_DUAL_PHOTO } from '@/constants/feature-flags';

import useLang from '@/hooks/useLang';
import useRsvp from '@/usecase/use-rsvp';
import useCobaLink from '@/hooks/useCobaLink';

import DetailCouple from './DetailCard';
import SinglePhoto from './SinglePhoto';

const backgroundTop = { backgroundPosition: 'top', isTop: true };
const backgroundBottom = { isTop: false, backgroundPosition: 'bottom' };

/**
 * Render couple info
 * @param {*} props
 * @returns
 */
function CoupleInfo({ ...rest }) {
  const lang = useLang();
  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  const renderBoy = (props = {}) => {
    return (
      <DetailCouple
        lang={lang}
        fullName={BOY_NAME}
        shortName={BOY_NAME_SHORT}
        parentName={BOY_PARENT_NAME}
        instagramId={IG_BOY}
        imgUrl={IMG_MAN}
        {...props}
      />
    );
  };

  const renderGirl = (props = {}) => {
    return (
      <DetailCouple
        lang={lang}
        fullName={GIRL_NAME}
        shortName={GIRL_NAME_SHORT}
        parentName={GIRL_PARENT_NAME}
        instagramId={IG_GIRL}
        imgUrl={IMG_GIRL}
        {...props}
      />
    );
  };

  if (!ENABLE_DUAL_PHOTO) {
    return <SinglePhoto {...rest} />;
  }

  return (
    <Box bgColor="bgPrimary" padding="62px 0 42px 0" {...rest}>
      {IS_BOY_FIRST ? renderBoy(backgroundTop) : renderGirl(backgroundTop)}
      <Box height="60px" />
      {IS_BOY_FIRST ? renderGirl(backgroundBottom) : renderBoy(backgroundBottom)}
    </Box>
  );
}

CoupleInfo.propTypes = {
  lang: string,
};

export default CoupleInfo;
