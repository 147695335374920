import React from 'react';

import { object, string } from 'prop-types';
import { Box, Center, Heading, Text, AspectRatio, Image } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';
import DividerComponent from '@/components/Common/Divider';

import { BUTTON_PROPS, BORDER_COLOR } from '@/constants/colors';

function DetailCouple(props) {
  const {
    imgUrl,
    instagramId,
    parentName,
    fullName,
    shortName,
    shortNameProps,
    instagramIdProps,
    fullNameProps,
    imgUrlProps,
    parentNameProps,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Box>
        <WithAnimation left>
          <Center maxHeight="400px">
            <AspectRatio width="100%" maxW="220px" ratio={3 / 4}>
              <Image
                src={imgUrl}
                objectPosition="center"
                margin="0 auto"
                borderRadius="50%"
                border="4px solid"
                borderColor={BORDER_COLOR}
                {...imgUrlProps}
              />
            </AspectRatio>
          </Center>
        </WithAnimation>
      </Box>
      <Box padding="24px">
        <WithAnimation>
          <Heading
            marginTop="-42px"
            color="secondaryColorText"
            textAlign="center"
            fontSize="6xl"
            fontWeight="normal"
            {...shortNameProps}
          >
            {shortName}
          </Heading>
        </WithAnimation>
        <WithAnimation left>
          <DividerComponent borderColor="bgAlternative" />
        </WithAnimation>
        <WithAnimation>
          <Text
            color="mainColorText"
            fontSize="xl"
            textAlign="center"
            margin="4px 0 24px 0"
            fontFamily="body"
            {...fullNameProps}
          >
            {fullName}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text
            color="mainColorText"
            textAlign="center"
            fontSize="sm"
            fontFamily="body"
            dangerouslySetInnerHTML={{ __html: parentName }}
            {...parentNameProps}
          />
        </WithAnimation>
        {instagramId && (
          <Center marginTop="24px">
            <WithAnimation>
              <InstagramButton
                id={instagramId}
                text={instagramId}
                color="white"
                onlyIcon={false}
                size="sm"
                fontFamily="body"
                fontWeight="normal"
                bgColor="bgAlternative"
                boxShadow="xl"
                {...BUTTON_PROPS}
                {...instagramIdProps}
              />
            </WithAnimation>
          </Center>
        )}
      </Box>
    </Box>
  );
}

DetailCouple.propTypes = {
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  parentName: string.isRequired,
  fullName: string.isRequired,
  shortName: string.isRequired,
  shortNameProps: object,
  instagramIdProps: object,
  fullNameProps: object,
  imgUrlProps: object,
  parentNameProps: object,
};

DetailCouple.defaultProps = {
  shortNameProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  imgUrlProps: {},
  parentNameProps: {},
};

export default DetailCouple;
