import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_BANK_TRANSFER } from '@/constants/feature-flags';

import QRBank from '@/images/bca.jpg';
import LogoBank from '@/images/bank.png';
import Bank from '@invitato/helpers/dist/constants/banks';

import txtWording from './locales';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="secondaryColorText" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="secondaryColorText"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };
  
  const renderBank2 = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="secondaryColorText" marginTop="0" fontWeight="bold">
          BSB: 062 028 <br />
          Account: {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="secondaryColorText"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Modal size="full" onClose={onClose} isOpen={visible}>
      <ModalOverlay />
      <ModalContent maxWidth="500px">
        <ModalCloseButton marginTop="12px" {...BUTTON_PROPS} />
        <ModalHeader />
        <ModalBody>
          <Heading
            textAlign="center"
            fontWeight="normal"
            fontSize="4xl"
            color="mainColorText"
            margin="24px 0 12px"
          >
            Bank Transfer
          </Heading>
          {ENABLE_BANK_TRANSFER && (
            <>
              {false && (
                <Center>
                  <Image
                    src={QRBank}
                    borderRadius="16px"
                    marginTop="8px"
                    maxWidth="200px"
                    boxShadow="md"
                    padding="8px"
                  />
                </Center>
              )}
              <Center>
                <Image
                  src={LogoBank}
                  margin="16px 0 -24px"
                  maxWidth="180px"
                />
              </Center>
              {renderBank2('', 'Tedy', '1194 8677')}
              {renderBank(Bank.bca, 'Tedy', '8170762526')}
            </>
          )}
          <Box height="50px" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;