import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { string } from 'prop-types';

import { Button } from '@chakra-ui/react';

import { EPOCH_START_EVENT, GOOGLE_MAPS_ADDRESS, THE_BRIDE, EPOCH_END_EVENT } from '@/constants';

dayjs.extend(utc);

function AddToCalendarButton({ text, ...rest }) {
  const handleClickAddToCalendar = () => {
    const title = `Wedding of ${THE_BRIDE} by Invitato.net`;
    const finalURL = `text=${encodeURIComponent(title)}`;
    const location = `location=${GOOGLE_MAPS_ADDRESS}`;

    const startDate = dayjs(EPOCH_START_EVENT * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');
    const endDate = dayjs(EPOCH_END_EVENT * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');

    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&${finalURL}&dates=${startDate}/${endDate}&${location}`,
      '_blank',
    );
  };
  return (
    <Button {...rest} onClick={handleClickAddToCalendar}>
      {text}
    </Button>
  );
}

AddToCalendarButton.propTypes = {
  text: string.isRequired,
};

export default AddToCalendarButton;
