import { css } from '@emotion/react';

export const styWishlishWrapper = css`
  .button__arrowdown {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    .button__arrowdown {
      transition: visibility 0s, opacity 0.5s linear;
      opacity: 0;
    }
  }
`;
