import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { BUTTON_PROPS } from '@/constants/colors';

import Confirmation from '../Confirmation/Lazy';

function ModalConfirmation({ lang, isOpen, onClose }) {
  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="500px">
        <ModalCloseButton color="transparent" {...BUTTON_PROPS} />
        <ModalBody padding="0">
          <Confirmation onBack={onClose} lang={lang} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalConfirmation.propTypes = {
  lang: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default ModalConfirmation;