import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Heading, Image } from '@chakra-ui/react';

import QRCard from '@/components/Common/QRCard/Lazy';
import WithAnimation from '@/components/Common/WithAnimation';

import { useGuest } from '@/context/guest';
import generateShift from '@/hooks/useShift';
import useInvitation from '@/hooks/useInvitation';

import {
  ASSETS_FLOWER_LEFT,
  IMG_AKAD,
  IMG_RECEPTION,
  // IMG_DRESSCODE,
} from '@/constants/assets';
import { ENABLE_MUSLIM_LANGUAGE, ENABLE_QR_INVITATION } from '@/constants/feature-flags';

import {
  WEDDING_AKAD_TIME,
  // WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_AKAD_DRESSCODE,
  // WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_DRESSCODE,
  GOOGLE_MAPS_AKAD,
  GOOGLE_MAPS_RESEPSI,
} from '@/constants';

import Detail from './Detail';
import txt from './locales';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  const { guest } = useGuest();
  const shiftTime = generateShift(guest.shift);
  const { code } = guest;

  return (
    <Box padding="42px 24px" bgColor="bgPrimary" {...rest}>
      <Center>
        <Image src={ASSETS_FLOWER_LEFT} height="60px" marginLeft="-134px" marginBottom="-37px" />
      </Center>
      <WithAnimation>
        <Heading
          lineHeight="90%"
          fontWeight="normal"
          fontSize="42px"
          textAlign="center"
          letterSpacing="2px"
          marginBottom="32px"
          color="mainColorText"
        >
          <span dangerouslySetInnerHTML={{ __html: txt.title[lang] }} />
        </Heading>
      </WithAnimation>
      <Detail
        lang={lang}
        items={[
          {
            title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
            imgUrl: IMG_AKAD,
            date: `Monday, 05 February 2024`,
            time: WEDDING_AKAD_TIME,
            dresscode: isInvitation && WEDDING_AKAD_DRESSCODE,
            location: WEDDING_AKAD_LOC_NAME,
            locationRoad: WEDDING_AKAD_LOC_ROAD,
            locationUrl: isInvitation && GOOGLE_MAPS_AKAD,
            show: true,
            // info: `<i><b>*) Family only</b> </i>`,
          },
          {
            title: txt.reception[lang],
            imgUrl: IMG_RECEPTION,
            date: `Monday, 05 February 2024`,
            time: shiftTime,
            dresscode: WEDDING_RESEPSI_DRESSCODE,
            location: WEDDING_RESEPSI_LOC_NAME,
            locationRoad: WEDDING_RESEPSI_LOC_ROAD,
            locationUrl: GOOGLE_MAPS_RESEPSI,
            show: isInvitation,
            // info: `Sunset Cocktail | 17:30 WITA <br /> Dinner Reception | 18.30 WITA`,
            // dresscodeUrl: IMG_DRESSCODE,
          },
        ]}
      />
      {code && ENABLE_QR_INVITATION && <QRCard marginTop="32px" />}
    </Box>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);
