import React, { useState, useEffect } from 'react';

import { Box, Center, Heading, Image, Text } from '@chakra-ui/react';
import { func, bool } from 'prop-types';

import { useGuest } from '@/context/guest';
import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';

import { BG_COVER, IMG_LOGO } from '@/constants/assets';
import { THE_BRIDE } from '@/constants';

import txt from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const isInvitation = useInvitation();
  const { isLoading } = useGuest();
  const lang = useLang();

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  const handleClickDetail = () => {
    if (loading) return;

    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    // side-effect to show next page after guest data ready
    // @important we use interval to make sure guest data is ready first before we go to next section
    if (loading) {
      let intervalGuest = setInterval(() => {
        if (!isLoading) {
          clearInterval(intervalGuest);
          handleLoadingComplete();
        }
      }, 1500);
    }
  }, [loading, isLoading, isInvitation]);

  return (
    <Box
      minHeight="100vh"
      bgColor="bgPrimary"
      bgImage={`url(${BG_COVER})`}
      bgSize="cover"
      bgPos="center"
    >
      <Box position="absolute" width="100%" maxWidth="500px">
        <Image src={IMG_LOGO} height="80px" position="absolute" top="20px" right="20px" />
      </Box>
      <Box bgColor="rgb(0 0 0 / 40%)" height="100vh" width="100%">
        <Center width="100%" zIndex="2" height="90vh">
          <Box width="85%" position="relative" height="100%">
            <Box
              color="secondaryColorText"
              letterSpacing="1px"
              fontWeight="400"
              fontSize="10px"
              width="100%"
              maxW="500px"
              padding="24px"
              bgSize="cover"
              borderRadius="16px"
              position="absolute"
              bottom="120px"
            >
              <Box textAlign="center" color="mainColorText">
                {/* BRIDE SECTION */}
                <WithAnimation>
                  <Text letterSpacing="4px" textShadow="1px 1px #000" color="white">
                    THE WEDDING OF
                  </Text>
                </WithAnimation>
                <WithAnimation>
                  <Heading
                    margin="16px 0"
                    fontWeight="normal"
                    color="white"
                    size="3xl"
                    letterSpacing="2px"
                    lineHeight="90%"
                    textShadow="1px 1px rgb(0 0 0 / 60%)"
                    dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
                  />
                </WithAnimation>
                {!isInvitation && (
                  <WithAnimation>
                    <Text
                      marginBottom="12px"
                      letterSpacing="4px"
                      textShadow="1px 1px #000"
                      color="white"
                    >
                      WEDDING ANNOUNCEMENT
                    </Text>
                  </WithAnimation>
                )}
              </Box>
              <WithAnimation>
                <ScrollToDown
                  loading={loading}
                  marginTop="8px"
                  text={isInvitation ? txt.invitation[lang] : txt.announcement[lang]}
                  bgColor="rgb(240 237 230 / 23%)"
                  color="white"
                  textTransform="uppercase"
                  boxShadow="lg"
                  fontFamily="body"
                  letterSpacing="1px"
                  size="sm"
                  fontSize="small"
                  className=""
                  onClick={handleClickDetail}
                />
              </WithAnimation>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
